import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [userCaptcha, setUserCaptcha] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptcha(result);
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Validate email
    if (!validateEmail(email)) {
      setPasswordError('Invalid email format');
      return;
    }

    // Validate password and confirm password
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    // Validate captcha
    if (captcha !== userCaptcha) {
      setCaptchaError('Invalid captcha');
      generateCaptcha();
      return;
    }

    // Sanitize user inputs
    const sanitizedEmail = email.trim();
    const sanitizedPassword = password.trim();

    // Replace with your actual registration API endpoint
    const regEndpoint = 'https://server.scri.ai/register';

    try {
      const response = await fetch(regEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: sanitizedEmail, password: sanitizedPassword }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);

      setIsLoggedIn(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to register', error);
      // Handle registration error (show message to user, etc.)
    }
  };

  return (
    <div className="reg-container">
      <h1>Register</h1>
      <p style={{ color: '#999' }}>
        Register your <strong>scri.ai</strong> account
      </p>
      <form onSubmit={handleRegister}>
        <div>
          <input
            type="email"
            value={email}
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <input
            type="password"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}<br />
        <div>
          Captcha:
          <p style={{fontSize: "25px"}}>{captcha}</p>
          <input
            type="text"
            value={userCaptcha}
            placeholder="Enter Captcha"
            onChange={(e) => setUserCaptcha(e.target.value)}
            required
          />
        </div>
        {captchaError && <p style={{ color: 'red' }}>{captchaError}</p>}
        <br />
        <button type="submit" className="dl" style={{ marginTop: '-10px', width: '300px' }}>
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;