import React, { useState, useEffect } from 'react';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { RiseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const Workers = () => {
    const refreshToken = localStorage.getItem('refreshToken');

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [dashboardData] = useWebSocket(`wss://server.scri.ai/ws/dashboard?token=${encodeURIComponent(token)}`, 'fetch');
  const [workers, setWorkers] = useState([]);
  const [generations, setGenerations] = useState([]);
  const [points, setPoints] = useState(0);
  const [email, setEmail] = useState('');
  let [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const refreshAccessToken = async () => {
    try {
      const response = await fetch('https://server.scri.ai/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        setToken(data.accessToken);
        return true;
      } else {
        console.error('Failed to refresh access token');
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return false;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(success => {
        if (!success) {
          console.log('Token refresh failed. User needs to log in again.');
          // Potentially redirect to login or perform another action
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
    }, 30000); // Refresh token every 1 minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    // Check if dashboardData is not null and contains workers before updating state
    if (dashboardData) {

      if (dashboardData.workers) {
        setWorkers(dashboardData.workers);
        // Sort generations by created_at in descending order
        if (dashboardData.generations) {
          // Sort generations by created_at in descending order
          const sortedGenerations = dashboardData.generations.sort((a, b) => b.created_at - a.created_at);
          
          setGenerations(sortedGenerations);
        }

      }
        setPoints(dashboardData.points);
        setEmail(dashboardData.email);
        setLoading(false);
    }
  }, [dashboardData]); // Dependency array ensures this effect runs only when dashboardData changes

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000); // Convert to milliseconds
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  function getStatusStyle(status) {
    switch (status) {
      case "offline":
        return { color: 'red' };
      case "idle":
        return { color: 'lightblue' };
      case "busy":
        return { color: 'green' };
      default:
        return {}; // Default style if needed
    }
  }

  function getOSLogo(platform) {
    switch (platform.toLowerCase()) {
        case "linux":
            return "fa-brands fa-linux";
        case "windows":
            return "fa-brands fa-windows";
        case "darwin":
            return "fa-brands fa-apple";
        case "freebsd":
            return "fa-brands fa-freebsd";
        default:
            return "fa-solid fa-desktop";
    }
}

  // Helper function to calculate duration and return as string
  const calculateDuration = (startEpoch, endEpoch) => {
    if (!endEpoch) return 'N/A'; // Or any placeholder you prefer
    const durationSeconds = endEpoch - startEpoch;
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = durationSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const [workersPage, setWorkersPage] = useState(0);
  const workersPerPage = 10; // Set the number of workers per page

  // Pagination state for generations
  const [generationsPage, setGenerationsPage] = useState(0);
  const generationsPerPage = 10; // Set the number of generations per page

  // Handlers for page changes
  const handleWorkersPageClick = ({ selected }) => {
    setWorkersPage(selected);
  };

  const handleGenerationsPageClick = ({ selected }) => {
    setGenerationsPage(selected);
  };

  // Calculating displayed items
  const displayedWorkers = workers.slice(workersPage * workersPerPage, (workersPage + 1) * workersPerPage);
  const displayedGenerations = generations.slice(generationsPage * generationsPerPage, (generationsPage + 1) * generationsPerPage);
  


  // The rest of your component...
  return (
    <>
    <div style={{ display: 'flex', height: '100%', direction: 'ltr'}}>
    <Sidebar collapsed={false} breakPoint="sm" className="sidebar" rootStyles={{
      container: "#0A0A0A",
      backgroundColor: '#0A0A0A',
      borderRight: '1px solid #222',
      minHeight: "100vh"
  }} backgroundColor='#0A0A0A'>
  <Menu rootStyles={{
      backgroundColor: '#0A0A0A',
      border: 'none'
  }}  menuItemStyles={{
    button: ({ level, active, disabled, hover }) => {
      // only apply styles on first level elements of the tree

        return {
          color: disabled ? '#eee' : '#fff',
          backgroundColor: active ? '#111' : '#0A0A0A',
          '&:hover': {
            backgroundColor: '#111',
          },
        };
    },
  }} className="menu">
    {/* <SubMenu label="Dashboard"> */}
    <MenuItem component={<Link to="/dashboard" />}><i className="fa-solid fa-gauge-high" style={{marginRight: "10px"}}></i> Dashboard </MenuItem>
      <MenuItem disabled style={{color: "#888"}}><i className="fa-solid fa-computer" style={{marginRight: "10px"}}></i> Workers</MenuItem>
      <MenuItem component={<Link to="/wallet" />}><i className="fa-solid fa-wallet" style={{marginRight: "10px"}}></i> Wallet</MenuItem>
    {/* </SubMenu> */}
    <MenuItem component={<Link to="/apidash" />}><i className="fa-solid fa-key" style={{marginRight: "10px"}}></i> API Keys</MenuItem>
    <MenuItem><i className="fa-solid fa-file" style={{marginRight: "12px", marginLeft: "1px"}}></i> Documentation</MenuItem>
    <MenuItem><i className="fa-solid fa-gear" style={{marginRight: "10px"}}></i> Settings</MenuItem>
  </Menu>
</Sidebar>

{loading ? (
    <>
    <div style={{ margin: "150px 0 auto", textAlign: "center", width: "100%" }}>
        <RiseLoader loading={loading} color="#fff" />
    </div>
    
    </>

) : (<>
    <div style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
<br />
      <div className="lg-container" style={{ marginTop: "20px", margin: "0 auto", textAlign: "center" }}>
        <br />
        <h2>My Workers</h2>
      <p style={{color: "#999"}}>Your workers are listed below. You can view the status of each worker and the platform it is running on.</p>
      <br />
        <div className="row">
        {displayedWorkers.map((worker, index) => (
            <div key={index} className="col-md-4 mb-4">
            <Link to={`/workers/${worker.workerID}`} style={{textDecoration: "none"}}>
                <div className="card text-white mb-3" style={{ maxWidth: "20rem", margin: "0 auto" }}>
                <div className="card-header">{worker.worker_name}</div>
                <div className="card-body">
                    <p className="card-text"><i className={getOSLogo(worker.platform)} style={{fontSize: "32px"}} /></p>
                    <p className="card-text">
                    Status: <strong style={getStatusStyle(worker.status)}>{worker.status.toUpperCase()}</strong>
                    </p>
                </div>
                </div>
            </Link></div>
            ))}
            {/* Workers display and pagination controls */}
      <div>{/* Display workers using displayedWorkers array */}</div>
      <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          pageCount={Math.ceil(workers.length / workersPerPage)}
          onPageChange={handleWorkersPageClick}
          containerClassName={'pagination'}
          buttonClassName={'pagi'}
          pageClassName={'pagi'}
          previousLinkClassName={'pagi'}
          nextLinkClassName={'pagi'}
          disabledClassName={'pagi--disabled'}
          activeClassName={'pagi--active'}
        />
        </div>
        </div>
        <br /><hr /><br />
        <div className="lg-container" style={{ marginTop: "20px", margin: "0 auto", textAlign: "center" }}>
        <h2>Total Generations <small>({generations.length})</small></h2>
        <p style={{color: "#b8b8b8"}}>All of your workers inference generations and their details</p>
        <div className="row">
        <div className="table-responsive">
          <table className="table tablea">
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Started</th>
                <th>Tokens</th>
                <th>Duration</th>
                <th>TPS</th>
                <th>Status</th>                
              </tr>
            </thead>
            <tbody>
              {displayedGenerations.map((generation, index) => (
                <tr key={index}>
                  <td>{generation.model_name}</td>
                    <td>{formatDate(generation.started_at)}</td>
                  <td>{generation.tokens}</td>
                  <td>{calculateDuration(generation.created_at, generation.ended_at.Int64)}</td>
                  <td>{generation.tokens_per_second.toFixed(2)}</td>
                  <td>{generation.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Generations display and pagination controls */}
      <div>{/* Display generations using displayedGenerations array */}</div>
      <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          pageCount={Math.ceil(generations.length / generationsPerPage)}
          onPageChange={handleGenerationsPageClick}
          containerClassName={'pagination'}
          buttonClassName={'pagi'}
          pageClassName={'pagi'}
          previousLinkClassName={'pagi'}
          nextLinkClassName={'pagi'}
          disabledClassName={'pagi--disabled'}
          activeClassName={'pagi--active'}
        />
        </div>
      </div>
      </div>
      </>
)}
      



      </div>
    </>
  );
};

export default Workers;
