import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Download = () => {
  // State to manage active key
  const [key, setKey] = useState('macOS');

  return (
    <div className="lg-container">
      <h1 style={{marginBottom: '20px', marginTop: "50px"}}>Download Scri.ai Client</h1>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        style={{borderBottom: "none", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center"}}
        align="center"
        // style={{ color: 'black' }} // Adjusted for visibility
      >
        <Tab eventKey="macOS" title={<span><i className="fa-brands fa-apple" style={{fontSize: "48px"}} /><br />macOS</span>}>
        <div style={{margin: "0 auto"}} align="center">
    
            <a href="/download"><button type="button" className="dl">Download for macOS ↓</button></a>
            <br /><br />
            <span style={{fontSize: "13px", color: "#999"}}><em>Apple Silicon recommended.</em></span>
            </div>
        </Tab>
        <Tab eventKey="Linux" title={<span><i className="fa-brands fa-linux" style={{fontSize: "48px"}} /><br />Linux</span>}>
<div style={{margin: "0 auto"}} align="center">
   
            <a href="/download"><button type="button" className="dl">Download for Linux ↓</button></a>
            <br /><br />
            <span style={{fontSize: "13px", color: "#999"}}><em>AMD and NVIDIA GPU supported. 8GB minimum VRAM.</em></span>
</div>
        </Tab>
        <Tab eventKey="Windows" title={<span><i className="fa-brands fa-windows" style={{fontSize: "48px"}} /><br />Windows</span>}>
        <div style={{margin: "0 auto"}} align="center">
      
            <a href="/download"><button type="button" className="dl">Download for Windows ↓</button></a>
            {/* Additional Windows-specific content */}
            <br /><br />
            <span style={{fontSize: "13px", color: "#999"}}><em>AMD and NVIDIA GPU supported. 8GB minimum VRAM.</em></span>
            </div>
        </Tab>
      </Tabs>
      <p style={{marginBottom: "450px"}}>&nbsp;</p>
    </div>
  );
}

export default Download;
