import React from 'react';

const APIPage = () => {
  return (
    <div className="container text-light" style={{ padding: '20px', borderRadius: '10px', marginTop: '20px', background: "#111" }}>
      <h1 className="text-light">API Documentation</h1>
      <p>The SCRI API provides an interface compatible with OpenAI's standards, allowing you to generate text completions based on the prompts you provide. Below you will find details on how to interact with the <code>/v1/chat/completions</code> endpoint.</p>
      <hr />
      <h1>API Billing</h1>
    <p>It currently costs 1,000 points per inference request on the API. You can earn points from running a worker node or buying on a secondary market.</p>
      <hr />
      <h2 className="text-light">Endpoint Description</h2>
      <p><code>https://server.scri.ai/v1/chats/completions</code></p>
      <p>This endpoint generates text completions for a given input prompt. It is designed to be flexible and powerful, allowing for a wide range of applications.</p>
      <hr />
      <h3 className="text-light">Parameters</h3>
      <ul className="text-light">
        <li><strong>model:</strong> Specifies the model to be used for generating completions. Available models include the following:<br />
            <ul>
                <li><code>dolphin-phi</code></li>
                <li><code>dolphin-mistral</code></li>
                <li><code>tinydolphin</code></li>
                <li><code>tinyllama</code></li>
                <li><code>llama3-uncensored</code></li>
                <li><code>wizard-vicuna-uncensored</code></li>
                </ul>
        
        </li><br />
        <li><strong>messages:</strong> An array of message objects, each containing a "role" (e.g., "user") and "content" (the input text).</li>
        <br /><li><strong>stream (optional):</strong> A boolean that, if true, instructs the server to keep the connection open and send results as they're generated. If false or omitted, the server will send a single response.</li>
        <br /><li><strong>temperature:</strong> Controls randomness in the generation process. Higher values mean more random completions.</li>
      </ul>
<hr />
      <h3 className="text-light">Usage Example</h3>
      <h4 className="text-secondary">Example POST Request</h4>
      <pre className="bg-dark text-light p-3">
{`curl -X POST "https://server.scri.ai/v1/chats/completions" \\
    -H "Authorization: Bearer YOUR_API_KEY" \\
    -H "Content-Type: application/json" \\
    -d '{
        "model": "dolphin-phi",
        "messages": [{"role": "user", "content": "Hello! Tell me about AMD in 500 words."}],
        "stream": false,
        "temperature": 0.7
    }'`}
      </pre>

      <h4 className="text-secondary">Example Output</h4>
      <pre className="bg-dark text-light p-3">
{`{
    "id": "55a0bf9dc654ff0d6852ebfe36f2af40",
    "object": "chat.completion",
    ...
    "choices": [
        {
            "message": {
                "role": "assistant",
                "content": "AMD (Advanced Micro Devices) is an American multinational..."
            },
            "finish_reason": "stop"
        }
    ],
    "usage": {
        "prompt_tokens": 38,
        "completion_tokens": 3989,
        "total_tokens": 4027
    }
}`}
      </pre>

      <p>When <code>stream</code> is set to <strong>true</strong>, the API will send partial results as they are generated, allowing for a more interactive experience. This is particularly useful for applications requiring real-time data or for large prompts where immediate feedback is beneficial.</p>

      <p>Note: Ensure that your client can handle chunked responses correctly when using streaming.</p>
    </div>
  );
};

export default APIPage;
