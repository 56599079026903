import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { Navbar, Container, Nav, Collapse, NavDropdown } from 'react-bootstrap';

const NNavbar = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setIsLoggedIn(true);
  }, [isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    // localStorage.removeItem('user');
    localStorage.clear();
    // kill websockets

    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <Navbar expand="lg" variant="dark">
      {/* <Container> */}

        <Navbar.Brand href="/">
          {/* &nbsp;&nbsp;<img src="/static/logo.png" alt="scri.ai" height="50px" /> */}
          &nbsp;&nbsp;scri.ai
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarText"
          onClick={() => setOpen(!open)}
          style={{ marginRight: '15px' }}
        />
        <Navbar.Collapse id="navbarText" in={open}>
        <Nav className="ms-auto" style={{textAlign: "center"}}>
          {/* <li className="nav-item active">
          <a className="nav-link" href="/">Home</a>
        </li> */}
        <ul className="navbar-nav" style={{marginRight: "15px"}}>
        <li className="nav-item">
          <a className="nav-link" href="/download">Download</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/leaderboard">Leaderboards</a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" href="/interface">Chat App</a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link" href="/api">API</a>
        </li>
            {/* <span className="navbar-text" style={{ display: 'inline-block', marginRight: '20px' }}> */}
            {/* <img src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='32'%20height='32'%20fill='none'%3e%3cpath%20fill='%23fff'%20d='M27.107%205.637a26.062%2026.062%200%200%200-6.602-2.074%2019.362%2019.362%200%200%200-.845%201.755%2024.24%2024.24%200%200%200-7.317%200%2018.744%2018.744%200%200%200-.856-1.755%2025.976%2025.976%200%200%200-6.607%202.08C.702%2011.955-.43%2018.112.136%2024.181c2.771%202.07%205.457%203.327%208.097%204.15a20.135%2020.135%200%200%200%201.734-2.857%2017.044%2017.044%200%200%201-2.73-1.329c.228-.17.452-.347.669-.53%205.265%202.463%2010.986%202.463%2016.188%200%20.22.183.443.36.67.53a17.01%2017.01%200%200%201-2.736%201.332c.5%201.002%201.08%201.958%201.734%202.856%202.643-.823%205.33-2.08%208.102-4.152.665-7.036-1.135-13.136-4.757-18.545zM10.684%2020.45c-1.58%200-2.877-1.475-2.877-3.272%200-1.797%201.269-3.275%202.877-3.275%201.609%200%202.905%201.475%202.877%203.275.003%201.797-1.268%203.272-2.877%203.272zm10.632%200c-1.581%200-2.877-1.475-2.877-3.272%200-1.797%201.268-3.275%202.877-3.275%201.608%200%202.904%201.475%202.877%203.275%200%201.797-1.27%203.272-2.877%203.272z'/%3e%3c/svg%3e" alt="Discord" height="26px"/>
            &nbsp;&nbsp;&nbsp;
            <img src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='32'%20height='32'%20fill='none'%3e%3cpath%20fill='%23fff'%20d='M11.789%2028c-3.235%200-6.25-.94-8.789-2.564%202.155.14%205.958-.195%208.324-2.451-3.559-.163-5.164-2.893-5.373-4.059.302.117%201.744.257%202.558-.07-4.093-1.026-4.721-4.619-4.582-5.715.768.537%202.07.723%202.582.676-3.814-2.729-2.442-6.834-1.767-7.72%202.737%203.792%206.84%205.922%2011.914%206.04a5.865%205.865%200%200%201-.146-1.305C16.51%207.61%2019.113%205%2022.325%205a5.79%205.79%200%200%201%204.25%201.853c1.122-.263%202.81-.878%203.634-1.41-.416%201.493-1.71%202.738-2.493%203.2.006.016-.007-.016%200%200%20.688-.104%202.549-.462%203.284-.96-.364.838-1.736%202.233-2.862%203.013C28.348%2019.938%2021.276%2028%2011.788%2028z'/%3e%3c/svg%3e" alt="Twitter"  height="26px"/> */}

              {isLoggedIn ? (
                <>
                  {/* <Link to="/dashboard" className="console" style={{ textDecoration: 'none', marginRight: "5px" }}>
                    Dashboard
                  </Link> */}
                  <NavDropdown align="end" title="Account" id="basic-nav-dropdown" className="nav-dropdown-dark dropdown-menu-right">
                    <NavDropdown.Item href="/dashboard"><i className="fa-solid fa-gauge-high" style={{marginRight: "5px"}}></i> Dashboard</NavDropdown.Item>
                    <NavDropdown.Item href="/workers"><i className="fa-solid fa-computer" style={{marginRight: "5px"}}></i> Workers</NavDropdown.Item>
                    <NavDropdown.Item href="/interface"><i className="fa-solid fa-comment" style={{marginRight: "5px"}}></i> Chat App</NavDropdown.Item>
                    <NavDropdown.Item href="/wallet"><i className="fa-solid fa-wallet" style={{marginRight: "5px"}}></i> Wallet</NavDropdown.Item>
                    <NavDropdown.Item href="/apidash"><i className="fa-solid fa-key" style={{marginRight: "5px"}}></i> API Keys</NavDropdown.Item>
                    <NavDropdown.Item href="/settings"><i className="fa-solid fa-gear" style={{marginRight: "5px"}}></i> Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                  </NavDropdown>

                  {/* <a href="#" onClick={handleLogout} className="console" style={{ textDecoration: 'none', marginLeft: '5px' }}>
                    Logout
                  </a> */}
                </>
              ) : (
                <>
                <span className="navbar-text" style={{ display: 'inline-block', marginRight: '20px' }}>
                  <Link to="/login" className="console" style={{ textDecoration: 'none', marginRight: "5px" }}>
                    Login
                  </Link>
                  <Link to="/register" className="console" style={{ textDecoration: 'none', marginLeft: "5px" }}>
                    Register
                  </Link></span>
                </>
              )}
            {/* </span> */}
            </ul>
          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}

    </Navbar>
  );
};

export default NNavbar;
