import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SSE } from 'sse.js';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';

const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={vscDarkPlus}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    blinkingDot() {
        return <span className="dot">⬤</span>;
      },
  };

const Interface = () => {
  const [prompt, setPrompt] = useState('');
  const [stream, setStream] = useState(true);
  const [response, setResponse] = useState('');
  const [oresponse, setOresponse] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [streamContent, setStreamContent] = useState('');
  const [streamDot, setStreamDot] = useState('⬤');

  const [estimatedCost, setEstimatedCost] = useState(0);
  const [connectedWorkers, setConnectedWorkers] = useState(0);

  const [conversationHistory, setConversationHistory] = useState([]);
  // const [homeData] = useWebSocket('wss://server.scri.ai/ws/home', 'RequestData');
  const [selectedModel, setSelectedModel] = useState('llama3');

  const responsesRef = useRef(null);

  const textareaRef = useRef(null);

  const [modelList, setModelList] = useState([]);

    const [abortController, setAbortController] = useState(null);

    const [stopSignal, setStopSignal] = useState(false);

    // useEffect(() => {
    //   // Check if homeData is not null and contains workers before updating state
    //   if (homeData && homeData.totalTokens) {
    //       // if (homeData.totalTokens === totalTokens) return; // No need to update state if the value hasn't changed
    //       // setTotalTokens(homeData.totalTokens);
    //       setConnectedWorkers(homeData.connectedWorkers);
    //       // setAvgTokens(homeData.avgTPS);
    //   }
    // }, [homeData]);

      // Define your chatbot's preparation instructions
  const preparationInstructions = "You are a friendly and knowledgeable uncensored assistant.";

  // Function to manage and prepare the conversation history for the model
  const manageAndPrepareConversationHistory = () => {
    const maxTokensForModel = modelList.find(model => model.name === selectedModel)?.maxTokens || 2048;
    
    let tokensCount = 0;
    let messagesForSubmission = [{ role: "system", content: preparationInstructions }];
  
    const estimateTokens = message => message.split(/\s+/).length;
    
    tokensCount += estimateTokens(preparationInstructions);
    
    // Limit conversation history to the last 9 messages (excluding the current user's message)
    const recentHistory = conversationHistory.slice(-9);
    
    for (let i = recentHistory.length - 1; i >= 0; i--) {
      const { role, content } = recentHistory[i];
      const messageTokens = estimateTokens(content);
    
      if (tokensCount + messageTokens <= maxTokensForModel) {
        tokensCount += messageTokens;
        messagesForSubmission.unshift({ role, content });
      } else {
        break;
      }
    }
    
    // Add the current user's message to the messagesForSubmission array
    const currentUserMessage = { role: "user", content: prompt };
    const currentUserMessageTokens = estimateTokens(prompt);
    
    if (tokensCount + currentUserMessageTokens <= maxTokensForModel) {
      messagesForSubmission.push(currentUserMessage);
    }
    
    return messagesForSubmission;
  };

  const calculateTotalTokens = (messages) => {
    // Implement based on your model's specifics
    return messages.reduce((total, msg) => total + msg.content.split(" ").length, 0);
  };


  const fetchModelList = async () => {
    try {
      const response = await fetch('https://server.scri.ai/models');
      const data = await response.json();
      setModelList(data.models);

      const res = await fetch('https://server.scri.ai/estimatecost');
      const data2 = await res.json();
      setEstimatedCost(data2.cost);
    } catch (error) {
      console.error('Error fetching model list:', error);
    }
  };

  useEffect(() => {
    fetchModelList();
  }, []);

//   textareaRef.current.focus();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (prompt.trim() === '') return;
      handleSubmit(event);
    }
  };

  const focusTextarea = () => {
    textareaRef.current.focus();
    textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
  };

  useEffect(() => {
    if (responsesRef.current) {
      responsesRef.current.scrollTop = responsesRef.current.scrollHeight;
    }
  }, [conversationHistory, streamContent]);

  const appendMessageToConversation = (role, content, startTime, endTime, model, tokensCount) => {
    setConversationHistory((prevHistory) => [...prevHistory, { role, content, startTime, endTime, model, tokensCount }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (prompt.trim() === '') return;
    setIsSubmitting(true);
    setResponse('');
    setStreamContent('');
    const startTime = new Date().getTime();
    const model = selectedModel;
    appendMessageToConversation('user', prompt, startTime, startTime, model);
  
    const newUserMessage = { role: 'user', content: prompt };
    const updatedConversationHistory = [...conversationHistory, newUserMessage];
  
    const messagesForSubmission = manageAndPrepareConversationHistory(updatedConversationHistory);
  
    const controller = new AbortController();
    setAbortController(controller);
  
    const url = 'https://server.scri.ai/v1/chat/completions'; // https://server.scri.ai/v1/chats/completions
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'nsk-0KcKlQHEBRt3iBdkzL_g-q-94tnM2OpX' // nsk-0KcKlQHEBRt3iBdkzL_g-q-94tnM2OpX
    };
    const payload = JSON.stringify({
      model: selectedModel,
      messages: messagesForSubmission,
      stream: stream,
      temperature: 0.7
    });
  
    setPrompt('');
  
    if (stream) {
      try {
        const source = new SSE(url, {
          headers: headers,
          payload: payload,
          method: 'POST',
          withCredentials: false
        });
  
        let content = '';
  
        source.addEventListener('message', (e) => {
          const data = e.data;
          if (data === '[DONE]') {
            const tokensCount = content.split(' ').length;
            appendMessageToConversation('assistant', content, startTime, new Date().getTime(), model, tokensCount);
            setIsSubmitting(false);
            focusTextarea();
            source.close();
            return;
          }
  
          try {
            const parsed = JSON.parse(data);
            const { choices } = parsed;
            if (choices && choices.length > 0) {
              const { delta } = choices[0];
              if (delta && delta.content !== undefined) {
                content += delta.content;
                setStreamContent(content + '⬤');
              }
            }
          } catch (error) {
            console.error('Error parsing JSON:', error, 'JSON string:', data);
          }
        });
  
        source.addEventListener('readystatechange', (e) => {
          if (e.readyState === 2) {
            setStreamContent(content + streamDot);
            setTimeout(() => {
              setStreamContent(content + '⬤');
            }, 200);
          }
        });
  
        source.addEventListener('error', (e) => {
          console.error('SSE errorrrr:', e);
          appendMessageToConversation('assistant', 'An error occurred while processing the request: '+JSON.parse(e.data).error, startTime, new Date().getTime(), model, 0);
          source.close();
          setIsSubmitting(false);
          focusTextarea();
        });
  
        source.stream();
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsSubmitting(false);
      }
    } else {
      try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: payload
      });
      const data = await response.json();
      if (data.choices && data.choices.length > 0) {
      setResponse(JSON.stringify(data, null, 2));
      const tokensCount = data.choices[0].message.content.split(' ').length;
      const endTime = new Date().getTime();
      appendMessageToConversation('assistant', data.choices[0].message.content, startTime, endTime, model, tokensCount);
      setIsSubmitting(false);
      focusTextarea();
      } else {
        console.error('Error fetching data:', data);
        appendMessageToConversation('assistant', 'An error occurred while processing the request: '+data.error, startTime, new Date().getTime(), model, 0);
        setIsSubmitting(false);
        focusTextarea();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    }
  };

  const handleModelSelect = (e) => {
    console.log(e.target.value);
    setSelectedModel(e.target.value);
    setConversationHistory([]);
  };

  const toggleSetStream = () => {
    setStream((prevState) => !prevState);
  };

  return (<><div style={{ display: 'flex', height: '100%', direction: 'ltr'}}>
    <Sidebar collapsed={false} breakPoint="sm" className="sidebar" rootStyles={{
      container: "#0A0A0A",
      backgroundColor: '#0A0A0A',
      borderRight: '1px solid #222',
  }} backgroundColor='#0A0A0A'>
  <Menu rootStyles={{
      backgroundColor: '#0A0A0A',
      border: 'none'
  }}  menuItemStyles={{
    button: ({ level, active, disabled, hover }) => {
      // only apply styles on first level elements of the tree

        return {
          color: disabled ? '#eee' : '#fff',
          backgroundColor: active ? '#111' : '#0A0A0A',
          '&:hover': {
            backgroundColor: '#111',
          },
        };
    },
  }} className="menu">
    {/* <SubMenu label="Dashboard"> */}
   

        <select onChange={handleModelSelect} style={{background: "#222", color: "#fff", padding: "5px", border: "none", outline: 'none', width:"100%"}} disabled={isSubmitting} placeholder="Select Model">
            {modelList.map((model, index) => (
            <option key={index} value={model.name}>{model.name}</option>
          ))}
        </select><br /><br />
        <MenuItem><div 
        style={{
        color: `${stream ? '#18a2e2' : '#FFF'}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        }} align="center">
        <input 
        type="checkbox" 
        id="switch" 
        onChange={toggleSetStream}
        checked={stream}
        align="center"
       
        /><label for="switch">Toggle</label><div style={{marginLeft: "10px"}}>Stream</div></div>
</MenuItem>

<MenuItem align="center" style={{color: "#999"}}>Estimated Cost</MenuItem>
<MenuItem align="center">{estimatedCost} Points</MenuItem>

<MenuItem align="center" style={{color: "#999"}}>Workers Available</MenuItem>
<MenuItem align="center">{connectedWorkers}</MenuItem>

<MenuItem align="center" style={{color: "#999"}}>Model Information</MenuItem>
<MenuItem align="center">Name: {selectedModel}</MenuItem>
<MenuItem align="center">Memory Req: {modelList.find(model => model.name === selectedModel)?.memoryRequirement}GB</MenuItem>
<MenuItem align="center">Max Tokens: {modelList.find(model => model.name === selectedModel)?.maxTokens}</MenuItem>
<MenuItem align="center">Parameter Size: {modelList.find(model => model.name === selectedModel)?.parameterSize}B</MenuItem>
{/* MemoryRequirement: 16, maxTokens: 4096, ParameterSize: 7, Info: */}
        {/* <MenuItem>Previous Chat History</MenuItem> */}
        {/* Threads for each prior chat TODO */}
        
  </Menu>
</Sidebar>
<div style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 77px)' }}>
      <div className="responsesContainer" ref={responsesRef} >
        {' '}<span className="rContainer">
        <select onChange={handleModelSelect} style={{background: "#222", color: "#fff", padding: "5px", border: "none", borderRadius: "5px", outline: 'none'}} disabled={isSubmitting} placeholder="Select Model">
            {modelList.map((model, index) => (
            <option key={index} value={model.name}>{model.name}</option>
          ))}
        </select>

        <input 
        type="checkbox" 
        id="switch" 
        onChange={toggleSetStream}
        checked={stream}
        /><label for="switch">Toggle</label>
      </span>
      </div>
      <div
        className="responsesContainer"
        ref={responsesRef}
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '20px',
          boxSizing: 'border-box',
          paddingBottom: '0px',
        }}
      >
        {conversationHistory.map((msg, index) => (
          <div key={index} className={msg.role === 'user' ? 'userMessage' : 'aiMessage'} style={{ fontSize: "16px", textAlign: msg.role === 'user' ? 'left' : 'left', color: msg.role === 'user' ? '#8adda8' : '#FFF', margin: '10px 0', background: msg.role === 'user' ? '#111' : '#222', borderRadius: "15px", padding: "15px", paddingTop: "10px" }}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", color: "#777", fontSize: "11px"}} className="row">
                <div className="col" style={{textAlign: "left"}}>
              {msg.role !== 'user' && <span>{msg.model}</span>}
              {msg.role === 'user' && <><span style={{textAlign:'left'}}>User Prompt</span></>}
              </div><div className="col" style={{textAlign: "right"}}>
              <span>{new Date(msg.startTime).toLocaleString('en-US')}</span>
              </div>
      
              
            </div><br />
            <ReactMarkdown components={components}>{msg.content}</ReactMarkdown>
            <hr />
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", color: "#777", fontSize: "11px"}}>
            <span>{Math.ceil(msg.content.split(' ').length * 100 / 75)} Tokens</span>{' '}
              {msg.role !== 'user' && <span>{((msg.endTime - msg.startTime) / 1000).toFixed(2)}s</span>}
              {msg.role !== 'user' && <span>{(Math.ceil(msg.content.split(' ').length * 100 / 75) / ((msg.endTime - msg.startTime) / 1000).toFixed(0)).toFixed(2)} T/s</span>}
              {/* <span>{new Date(msg.startTime).toLocaleString('en-US')}</span> */}
            </div>
          </div>
        ))}
        {isSubmitting && stream && (
        <div className="aiMessage" style={{ textAlign: 'left', color: '#FFF', margin: '10px 0', background: '#222', borderRadius: "15px", padding: "15px", paddingTop: "10px" }}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", color: "#777", fontSize: "11px"}}>
            <span>{selectedModel}</span>
            <hr />
            </div>
            {isSubmitting && stream && streamContent === '' && (
            <div align="center">
                <img src="./static/sspinner.gif" alt="Loading..." width="50px" />
            </div>
            )}
            <ReactMarkdown components={components}>{streamContent}</ReactMarkdown>
        </div>
        )}
        {isSubmitting && !stream && (
          <div align="center">
            <img src="./static/spinner.gif" alt="Loading..." width="50px" />
          </div>
        )}
      </div>
      <div
  className="textAreaContainer"
  style={{
    backgroundColor: '#111',
    padding: '20px',
    boxSizing: 'border-box',
  }}
>
  <form
    onSubmit={handleSubmit}
    style={{
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    }}
  >
    <div style={{ position: 'relative' }}>
      <textarea
        ref={textareaRef}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Enter your prompt here..."
        rows="1"
        disabled={isSubmitting}
        style={{
          width: '100%',
          borderRadius: '15px',
          padding: '10px',
          background: '#222',
          userSelect: 'none',
          outline: 'none',
          color: '#fff',
          paddingRight: '50px',
          paddingLeft: '20px',
          paddingTop: '14px',
          height: '52px',
          overflowY: 'hidden',
        }}
      />
      {/* <div style={{ position: 'absolute', bottom: '20px', left: '10px' }}>
        <button
          className="btn relative p-0 text-white"
          aria-label="Attach files"
          style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
          disabled={true}
        >
          <div className="flex w-full gap-2 items-center justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </button>
        <input multiple type="file" tabIndex="-1" style={{ display: 'none' }} />
      </div> */}
      <button
        type="button"
        onClick={(event) => {
            if (isSubmitting && abortController) {
            abortController.abort();
            setStopSignal(true);
            } else {
            handleSubmit(event);
            }
        }}
        // disabled={isSubmitting || (prompt.trim() !== '' ? false : true)}
        // style={{
        //     // ...
        //     background: isSubmitting ? '#ff0000' : prompt.trim() !== '' ? '#FFF' : '#333',
        // }}
        style={{
            position: 'absolute',
            bottom: '17px',
            right: '10px',
            borderRadius: '10px',
            border: 'none',
            background: isSubmitting ? 'transparent' : prompt.trim() !== '' ? '#FFF' : '#333',
            color: isSubmitting ? '#FFF' : '#111',
            padding: isSubmitting ? '0px 10px' : '5px 15px',
            fontSize: isSubmitting ? '22px' : '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
        >
        {isSubmitting ? <i className="fa-regular fa-circle-stop" /> : <i className="fa-solid fa-arrow-up" />}
        </button>
    </div>
    <p style={{fontSize: "11px", color: "#777", margin: "0 auto", textAlign: "center"}}>LLMs can make mistakes. Consider checking important information.</p>
  </form>
</div>
    </div></div></div>
  </>);
};

export default Interface;