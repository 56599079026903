// useWebSocket.js

// import { useState, useEffect } from 'react';

// const useWebSocket = (url, actionType) => {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     const webSocket = new WebSocket(url);

//     webSocket.onopen = () => {
//       console.log("WebSocket connection established");
//       // Send a message to the server to request specific data based on actionType
//       webSocket.send(JSON.stringify({ action: actionType }));
//     };

//     webSocket.onmessage = (event) => {
//       const message = JSON.parse(event.data);
//       // Process the message based on the action type
//     //   console.log(message)
//       if (message.type === actionType) {
//         setData(message);
//       } else {
//         setData(message);
//       }
//     };

//     return () => {
//       webSocket.close();
//     };
//   }, [url, actionType]);

//   return [data];
// };

// export default useWebSocket;


import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const useWebSocket = (url, actionType) => {
  const [data, setData] = useState(null);
  const webSocket = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to initialize WebSocket connection
    function initializeWebSocket() {
      webSocket.current = new WebSocket(url);

      webSocket.current.onopen = () => {
        // console.log("WebSocket connection established");
        if (webSocket.current.readyState === WebSocket.OPEN) {
          webSocket.current.send(JSON.stringify({ action: actionType }));
        }
      };

      webSocket.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === actionType) {
            if (message === data) return;
            setData(message);
        } else {
            if (message === data) return;
            setData(message);
        }
      };

      webSocket.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        localStorage.removeItem('token');

        navigate('/login');
      };

      webSocket.current.onclose = () => {
        // console.log("WebSocket connection closed");
      };
    }

    // Only create a new WebSocket connection if there isn't an existing one
    if (!webSocket.current || webSocket.current.readyState === WebSocket.CLOSED) {
      initializeWebSocket();
    }

    // Cleanup function to close WebSocket connection when the component unmounts
    // or if url/actionType changes
    return () => {
      if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
        webSocket.current.close();
      }
    };
  }, [url, actionType]);

  return [data];
};

export default useWebSocket;


