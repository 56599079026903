import React, { useState, useEffect, useCallback } from 'react';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { RiseLoader } from 'react-spinners';

const Leaderboard = () => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [workersPage, setWorkersPage] = useState(0);
  const workersPerPage = 10;

  const refreshToken = localStorage.getItem('refreshToken');

  const [token, setToken] = useState(localStorage.getItem('token'));

  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkers();
  }, []);

  const fetchWorkers = async () => {
    try {
      const response = await fetch('https://server.scri.ai/leaderboard');
      const data = await response.json();
      const filteredWorkers = data.workers.filter(worker => worker.ranking !== 0);
      setWorkers(filteredWorkers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching workers:', error);
      setLoading(false);
    }
  };

  const getOSLogo = (platform) => {
    switch (platform.toLowerCase()) {
      case "linux":
        return "fa-brands fa-linux";
      case "windows":
        return "fa-brands fa-windows";
      case "darwin":
        return "fa-brands fa-apple";
      case "freebsd":
        return "fa-brands fa-freebsd";
      default:
        return "fa-solid fa-desktop";
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "offline":
        return { color: 'red' };
      case "idle":
        return { color: 'lightblue' };
      case "busy":
        return { color: 'green' };
      default:
        return {};
    }
  };

  const handleWorkersPageClick = ({ selected }) => {
    setWorkersPage(selected);
  };

  const refreshAccessToken = async () => {
    try {
      const response = await fetch('https://server.scri.ai/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        setToken(data.accessToken);
        return true;
      } else {
        console.error('Failed to refresh access token');
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return false;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(success => {
        if (!success) {
          console.log('Token refresh failed. User needs to log in again.');
          // Potentially redirect to login or perform another action
          localStorage.removeItem('token');

          navigate('/login');
        }
      });
    }, 30000); // Refresh token every 1 minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const displayedWorkers = workers.slice(workersPage * workersPerPage, (workersPage + 1) * workersPerPage);

  return (
    <div style={{ margin: "0 auto", textAlign: "center", width: "100%", marginTop: "50px" }}>
      <h2>Workers Leaderboard</h2><br />
      {loading ? (
        <div style={{ margin: "150px 0 auto", textAlign: "center", width: "100%" }}>
          <RiseLoader loading={loading} color="#fff" />
        </div>
      ) : (
        <div className="xlg-container">
        <div className="table-responsive">
          <table className="table tablea">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Worker</th>
                <th>Platform</th>
                <th>CPU Cores</th>
                <th>Memory</th>
                <th>GPU</th>
                <th>Status</th>
                <th>Points</th>
                <th>Inference</th>
                <th>TPS</th>
              </tr>
            </thead>
            <tbody>
              {displayedWorkers.map((worker, index) => (
                <tr key={worker.workerID} style={{justifyContent: "center", verticalAlign: "middle"}}>
                  <td>#{(workersPage * workersPerPage) + index + 1}</td>
                  <td>
                    {worker.workerName}
                  </td>
                  <td>
                    <i className={getOSLogo(worker.platform)} style={{ fontSize: "24px" }} />
                  </td>
                  <td>{worker.cpuCores}</td>
                  <td>{worker.memory} GB</td>
                  <td>  {worker.gpu ? (
                        Array.isArray(JSON.parse(worker.gpu)) ? (
                        JSON.parse(worker.gpu).map((gpu, index) => (
                            <span key={index}>
                            {gpu.name} 
                            {/* {gpu.vram}GB (x{gpu.count}) */}
                            </span>
                        ))
                        ) : (<>
                        No GPU</>
                        )
                    ) : (
                        <>No GPU</>
                    )}</td>
                  <td>
                    <strong style={getStatusStyle(worker.status)}>{worker.status.toUpperCase()}</strong>
                  </td>
                  <td>{worker.points}</td>
                  <td>{worker.inferenceCount}</td>
                  <td>{worker.tokensPerSecond.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'← Previous'}
            nextLabel={'Next →'}
            pageCount={Math.ceil(workers.length / workersPerPage)}
            onPageChange={handleWorkersPageClick}
            containerClassName={'pagination'}
            buttonClassName={'pagi'}
            pageClassName={'pagi'}
            previousLinkClassName={'pagi'}
            nextLinkClassName={'pagi'}
            disabledClassName={'pagi--disabled'}
            activeClassName={'pagi--active'}
          />
        </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;