import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js'; // This is not required when using bootstrap with React

import NNavbar from './components/Navbar.js';
import Home from './components/Home.js';
import Login from './components/Login.js';
import Download from './components/Download.js';
import Dashboard from './components/Dashboard.js';
import Workers from './components/Workers.js';
import Worker from './components/Worker.js';
import APIDash from './components/APIDash.js';
import Interface from './components/Interface.js';
import Register from './components/Register.js';
import Wallet from './components/Wallet.js';
import Footer from './components/Footer.js';
import Leaderboard from './components/Leaderboard.js';
import APIPage from './components/APIPage.js';
import { AuthProvider } from './AuthContext'; 

// Import wallet adapter styles
import '@solana/wallet-adapter-react-ui/styles.css';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

const WalletConnectionProvider = ({ children }) => {
  const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter({ network: 'mainnet-beta' })];
  const network = 'mainnet-beta';
  
  return (
    <ConnectionProvider endpoint={clusterApiUrl(network)}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {children}
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
      <WalletConnectionProvider>
        <div className="App">
          <Routes>
            <Route exact path="/" element={
              <>
                <NNavbar />
                <Home />
                <Footer />
              </>
            } />
            <Route path="/login" element={
              <>
                <NNavbar />
                <Login />
                
              </>
            } />
            <Route path="/register" element={
              <>
                <NNavbar />
                <Register />
              </>
            } />
            <Route path="/download" element={
              <>
                <NNavbar />
                <Download />
                <Footer />
              </>
            } />
            <Route path="/leaderboard" element={
              <>
                <NNavbar />
                <Leaderboard />
                <Footer />
              </>
            } />
            <Route path="/api" element={
              <>
                <NNavbar />
                <APIPage />
                <Footer />
              </>
            } />
            <Route path="/interface" element={<><NNavbar /><Interface /></>} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <>
                    <NNavbar />
                    <Dashboard />
                    <Footer />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <PrivateRoute>
                  <>
                    <NNavbar />
                    <Wallet />
                    <Footer />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/workers"
              element={
                <PrivateRoute>
                  <>
                    <NNavbar />
                    <Workers />
                    <Footer />
                  </>
                </PrivateRoute>
              }
            />
            <Route 
              path="/workers/:workerId" 
              element={
                <PrivateRoute>
                  <>
                    <NNavbar />
                    <Worker />
                    <Footer />
                  </>
                </PrivateRoute>
              } 
            />
            <Route
              path="/apidash"
              element={
                <PrivateRoute>
                  <>
                    <NNavbar />
                    <APIDash />
                    <Footer />
                  </>
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </WalletConnectionProvider>
      </Router>
    </AuthProvider>
  );
}

// Redirects to login page if user is not authenticated
const PrivateRoute = ({ children }) => {
  const isAuthenticated = () => {
    // Check if token is present in local storage or session storage
    // You may need to verify the token's validity with your backend
    const token = localStorage.getItem('token'); // or sessionStorage
    return !!token;
  };

  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default App;