import React, { useState, useEffect } from 'react';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { RiseLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const Worker = () => {
  const { workerId } = useParams();
  const refreshToken = localStorage.getItem('refreshToken');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [dashboardData] = useWebSocket(`wss://server.scri.ai/ws/dashboard?token=${encodeURIComponent(token)}`, 'fetch');
  const [worker, setWorkerDetail] = useState('');
  const [generations, setGenerations] = useState([]);
  let [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteWorker = async () => {
    try {
      const response = await fetch('https://server.scri.ai/worker/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ worker_id: parseInt(workerId) }),
      });

      if (response.ok) {
        // Worker deleted successfully
        closeDeleteModal();
        // Redirect to workers page or update the worker list
        navigate('/workers');
      } else {
        // Handle error case
        console.error('Failed to delete worker');
      }
    } catch (error) {
      console.error('Error deleting worker:', error);
    }
  };


  const refreshAccessToken = async () => {
    try {
      const response = await fetch('https://server.scri.ai/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        setToken(data.accessToken);
        return true;
      } else {
        console.error('Failed to refresh access token');
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return false;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(success => {
        if (!success) {
          console.log('Token refresh failed. User needs to log in again.');
          // Potentially redirect to login or perform another action
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
    }, 30000); // Refresh token every 1 minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    // Check if dashboardData is not null and contains workers before updating state
    if (dashboardData && dashboardData.workers) {
        // Filter for this worker's details based on workerId
        const thisWorkerDetail = dashboardData.workers.find(worker => worker.workerID.toString() === workerId);
        setWorkerDetail(thisWorkerDetail);
  
        // Assuming each generation has a workerId field to identify which worker it belongs to
        // Filter generations for this worker and sort them by created_at in descending order
        if (dashboardData.generations) {

        
          const sortedGenerations = dashboardData.generations
            .filter(gen => gen.workerID.toString() === workerId)
            .sort((a, b) => b.created_at - a.created_at);
          
          setGenerations(sortedGenerations);
        }
        setLoading(false);
      }
    }, [workerId, dashboardData]);  // Dependency array ensures this effect runs only when dashboardData changes

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000); // Convert to milliseconds
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  function getStatusStyle(status) {
    switch (status) {
      case "offline":
        return { color: 'red', fontSize: '21px' };
      case "idle":
        return { color: 'lightblue', fontSize: '21px' };
      case "busy":
        return { color: 'green', fontSize: '21px' };
      default:
        return {}; // Default style if needed
    }
  }

  // Helper function to calculate duration and return as string
  const calculateDuration = (startEpoch, endEpoch) => {
    if (!endEpoch) return 'N/A'; // Or any placeholder you prefer
    const durationSeconds = endEpoch - startEpoch;
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = durationSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  // Pagination state for generations
  const [generationsPage, setGenerationsPage] = useState(0);
  const generationsPerPage = 10; // Set the number of generations per page

  // Handlers for page changes
//   const handleWorkersPageClick = ({ selected }) => {
//     setWorkersPage(selected);
//   };

  const handleGenerationsPageClick = ({ selected }) => {
    setGenerationsPage(selected);
  };

  // Calculating displayed items
  const displayedGenerations = generations.slice(generationsPage * generationsPerPage, (generationsPage + 1) * generationsPerPage);
  

  return (
    <>
    <div style={{ display: 'flex', height: '100%', direction: 'ltr'}}>
    <Sidebar collapsed={false} breakPoint="sm" className="sidebar" rootStyles={{
      container: "#0A0A0A",
      backgroundColor: '#0A0A0A',
      borderRight: '1px solid #222',
      minHeight: "100vh"
  }} backgroundColor='#0A0A0A'>
  <Menu rootStyles={{
      backgroundColor: '#0A0A0A',
      border: 'none'
  }}  menuItemStyles={{
    button: ({ level, active, disabled, hover }) => {
      // only apply styles on first level elements of the tree

        return {
          color: disabled ? '#eee' : '#fff',
          backgroundColor: active ? '#111' : '#0A0A0A',
          '&:hover': {
            backgroundColor: '#111',
          },
        };
    },
  }} className="menu">
    {/* <SubMenu label="Dashboard"> */}
    <MenuItem component={<Link to="/dashboard" />}><i className="fa-solid fa-gauge-high" style={{marginRight: "10px"}}></i> Dashboard </MenuItem>
      <MenuItem component={<Link to="/workers" />}><i className="fa-solid fa-computer" style={{marginRight: "10px"}}></i> Workers</MenuItem>
      <MenuItem component={<Link to="/wallet" />}><i className="fa-solid fa-wallet" style={{marginRight: "10px"}}></i> Wallet</MenuItem>
    {/* </SubMenu> */}
    <MenuItem component={<Link to="/apidash" />}><i className="fa-solid fa-key" style={{marginRight: "10px"}}></i> API Keys</MenuItem>
    <MenuItem><i className="fa-solid fa-file" style={{marginRight: "12px", marginLeft: "1px"}}></i> Documentation</MenuItem>
    <MenuItem><i className="fa-solid fa-gear" style={{marginRight: "10px"}}></i> Settings</MenuItem>
  </Menu>
</Sidebar>

{loading ? (
    <>
    <div style={{ margin: "150px 0 auto", textAlign: "center", width: "100%" }}>
        <RiseLoader loading={loading} color="#fff" />
    </div>
    
    </>

) : (<>
    <div style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
      {worker && (<>
        <div className="lg-container" style={{ marginTop: "20px", margin: "0 auto", textAlign: "center" }}>
            <br />
          <h2>Worker {worker.worker_name}</h2>
          <p style={{color: "#999"}}>Details about your worker and its inference generations from the scri.ai API</p>
          <div className="row">

            <div className="col-md-2">
                <div className="smcard">
                <small style={{color: "#999"}}>Status</small>
                <p style={getStatusStyle(worker.status)}>{worker.status.toUpperCase()}</p>
                </div>
            </div>
            <div className="col-md-2">
                <div className="smcard">
                <small style={{color: "#999"}}>Platform</small>
                <p style={{fontSize: "21px"}}>{worker.platform}</p>
                </div>
            </div>
            <div className="col-md-2">
            <div className="smcard">
                <small style={{color: "#999"}}>Memory GB</small>
                <p style={{fontSize: "21px"}}>~{worker.memory} GB</p>
                </div>
            </div>
            <div className="col-md-2">
            <div className="smcard">
                <small style={{color: "#999"}}>CPU Cores</small>
                <p style={{fontSize: "21px"}}>{worker.cpu_cores}</p>
                </div>
            </div>
            <div className="col-md-2">
            <div className="smcard">
                <small style={{color: "#999"}}>IP Address</small>
                <p style={{fontSize: "21px"}}>{worker.ipaddress}</p>
                </div>
            </div>
            <div className="col-md-2">
            <div className="smcard">
                <small style={{color: "#999"}}>Inference Reqs</small>
                <p style={{fontSize: "21px"}}>{worker.inference_count}</p>
                </div>
            </div>
        </div><br />
        <div className="row">
  <div className="col">
  <div className="smcard">
  <small style={{ color: "#999" }}>GPU(s)</small>
  {worker.gpu ? (
    Array.isArray(JSON.parse(worker.gpu)) ? (
      JSON.parse(worker.gpu).map((gpu, index) => (
        <p key={index} style={{ fontSize: "21px" }}>
          {gpu.name} {gpu.vram}GB (x{gpu.count})
        </p>
      ))
    ) : (
      <p style={{ fontSize: "21px" }}>No GPUs</p>
    )
  ) : (
    <p style={{ fontSize: "21px" }}>No GPUs</p>
  )}
</div>
  </div>
  </div>
  <br />
  <div className="row">
  <div className="col">
            <div className="smcard">
                <small style={{color: "#999"}}>TPS Benchmark</small>
                <p style={{fontSize: "21px"}}>{worker.tokens_per_second.toFixed(2)}</p>
                </div>
            </div>

            <div className="col">
            <div className="smcard">
                <small style={{color: "#999"}}>Worker Rank</small>
                <p style={{fontSize: "21px"}}>#{worker.ranking}</p>
                </div>
            </div>
</div>
        </div>

        </>
      )}
      <br />
      <button onClick={openDeleteModal} className="keybtnsmr">Delete Worker</button>

{/* <Modal
  isOpen={isDeleteModalOpen}
  onRequestClose={closeDeleteModal}
  contentLabel="Delete Worker Confirmation"
>
  <h2>Confirm Worker Deletion</h2>
  <p>Are you sure you want to delete this worker?</p>
  <button onClick={handleDeleteWorker}>Yes, Delete</button>
  <button onClick={closeDeleteModal}>Cancel</button>
</Modal> */}

<Modal show={isDeleteModalOpen} onHide={() => setIsDeleteModalOpen(false)}>
    <Modal.Header>
        <Modal.Title>Delete Worker</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to delete this worker?</Modal.Body>
    <Modal.Footer>
        <button className="console" onClick={() => setIsDeleteModalOpen(false)}>
        Cancel
        </button>
        <button className="keybtnsmr" onClick={() => {
        handleDeleteWorker();
        setIsDeleteModalOpen(false); // Close the modal after deletion
        }}>
        Delete
        </button>
    </Modal.Footer>
    </Modal>
      <hr />
      <br />
      <h3>Worker Generations <small>({generations.length})</small></h3>
        <p style={{color: "#999"}}>Inference generations from this worker</p>
      <div className="row lg-container">
        <div className="table-responsive">
          <table className="table tablea">
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Started</th>
                <th>Tokens</th>
                <th>Duration</th>
                <th>TPS</th>
                <th>Status</th>                
              </tr>
            </thead>
            <tbody>
              {displayedGenerations.map((generation, index) => (
                <tr key={index}>
                  <td>{generation.model_name}</td>
                    <td>{formatDate(generation.started_at)}</td>
                  <td>{generation.tokens}</td>
                  <td>{calculateDuration(generation.created_at, generation.ended_at.Int64)}</td>
                  <td>{generation.tokens_per_second.toFixed(2)}</td>
                  <td>{generation.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Generations display and pagination controls */}
      <div>{/* Display generations using displayedGenerations array */}</div>
      <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          pageCount={Math.ceil(generations.length / generationsPerPage)}
          onPageChange={handleGenerationsPageClick}
          containerClassName={'pagination'}
          buttonClassName={'pagi'}
          pageClassName={'pagi'}
          previousLinkClassName={'pagi'}
          nextLinkClassName={'pagi'}
          disabledClassName={'pagi--disabled'}
          activeClassName={'pagi--active'}
        />
        </div>
    </div></>)}
    </div></>
  );
}

export default Worker;
