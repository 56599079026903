import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReducer } from 'react';
import { useAuth } from '../AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    // Replace with your actual login API endpoint
    const loginEndpoint = 'https://server.scri.ai/login'; 

    try {
      const response = await fetch(loginEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem('token', data.accessToken); // Assuming the response contains a token
      localStorage.setItem('refreshToken', data.refreshToken); // Assuming the response contains a refresh token
      // need to ensure isLoggedIn is set and re-rendering whatever page the user is on like dashboard
      setIsLoggedIn(true);
      navigate('/dashboard')
      // Redirect to the dashboard after login
    //   navigate('/dashboard');
    } catch (error) {
      console.error('Failed to login', error);
      // Handle login error (show message to user, etc.)
    }
  };

  return (
    <div className="login-container">
        <h1>Login</h1>

        <p style={{color: "#999"}}>Login to your <strong>scri.ai</strong> account</p>
        
      <form onSubmit={handleLogin}>
        <div>

          <input
            type="email"
            value={email}
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>

          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div><br />
        <button type="submit" className="dl" style={{marginTop: "-10px", width: "300px"}}>Login</button>
      </form>
    </div>
  );
};

export default Login;
