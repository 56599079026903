import React, {useState, useEffect} from 'react';
import useWebSocket from '../useWebSocket';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, fromUnixTime } from 'date-fns';
import Lottie from 'react-lottie';
// import animationData from 'https://lottie.host/3a350f9a-ee8a-4678-9288-2e5f10f49a0a/EkuHgb9kn9.json';

import { DotLottiePlayer, Controls } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

const Home = () => {
    const [homeData] = useWebSocket('wss://server.scri.ai/ws/home', 'RequestData'); // Your WebSocket URL
    const [totalTokens, setTotalTokens] = useState(0);
    const [connectedWorkers, setConnectedWorkers] = useState(0);
    const [avgTokens, setAvgTokens] = useState(0);
    let [barGraphData, setBarGraphData] = useState({})
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Adjust URL as necessary
            const result = await fetch('https://server.scri.ai/inference-data');
            const json = await result.json();
            if (json && json.length > 0) {
            const formattedData = json.map(item => ({
                ...item,
                hourBlockDisplay: format(fromUnixTime(item.createdAt), 'PPpp'),
            }));

            setData(formattedData);
          } else {
            setData([]);
          }
        };

        fetchData();
    }, []);

    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: animationData,
    //   rendererSettings: {
    //     preserveAspectRatio: "xMidYMid slice"
    //   }
    // };


  useEffect(() => {
    // Check if homeData is not null and contains workers before updating state
    if (homeData && homeData.totalTokens) {
        if (homeData.totalTokens === totalTokens) return; // No need to update state if the value hasn't changed
        setTotalTokens(homeData.totalTokens);
        setConnectedWorkers(homeData.connectedWorkers);
        setAvgTokens(homeData.avgTPS);
    }
  }, [homeData]); // Dependency array ensures this effect runs only when homeData changes
  
  return (
    <>
<div style={{margin: "50px", textAlign: "center"}}>
  <div className="sm-container"><p style={{fontSize: "32px"}}><strong>S</strong>calable <strong>C</strong>ompute <strong>R</strong>esources for <strong>Inference</strong></p>
  <p style={{color: "#b8b8b8"}}>Earn points to contribute your idle compute power to the Scri.ai network. Use our network to do inference with popular and uncensored models like Llama3, dolphin-phi, tinydolphin, dolphin-mistral, and more all while using an OpenAI compatible API or our app.</p>
  <p><br /><a href="/download"><button type="button" className="dl">Download ↓</button></a></p>

  </div>

  </div>
  <div style={{height: "250px", marginBottom: "50px"}}>
  <DotLottiePlayer
        src="/gpu.lottie"
        autoplay
        loop
      >
        {/* <Controls /> */}
      </DotLottiePlayer>
      </div>
      <main className="md-container">
<div className="row">
          <div className="col-md-12">
                <div className="card" id="rpm">
                  <h4 style={{color:"#777"}}>Tokens Processed</h4>
                  <p className="big-number"><span id="totalTokens">{totalTokens}</span></p>
                  <p className="update-time">Updated in real-time</p>
              </div> 
              </div>
              </div>
          <section className="statistics row" style={{marginTop: "25px", marginBottom: "55px"}}>
            
              <div className="col-md-6" style={{marginBottom: "25px"}}>
              <div className="card" id="workers">
                  <h4 style={{color:"#777"}}>Online Workers</h4>
                  <p className="big-number"><span id="connectedWorkers">{connectedWorkers}</span></p>
                  <p className="update-time">Updated in real-time</p>
              </div>
              </div>
              
              <div className="col-md-6">
                <div className="card" id="payout">
                  <h4 style={{color:"#777"}}>Average TPS</h4>
                  <p className="big-number"><span id="avgTokens">{avgTokens.toFixed(2)}</span></p>
                  <p className="update-time">Updated in real-time</p>
              </div> 
              </div>
              
          </section>
          <p style={{fontSize: "32px"}}>The <strong>next generation</strong> of AI inference processing</p>
          <p style={{color: "#b8b8b8"}}>Try out our OpenAI compatible API. Many LLM models to choose from!</p>
  <p><br /><a href="/api"><button type="button" className="dl">Try the API →</button></a></p><br />
  <div style={{height: "350px", marginBottom: "0px"}}>
  <DotLottiePlayer
        src="/ai.lottie"
        autoplay
        loop
      >
        {/* <Controls /> */}
      </DotLottiePlayer>
      </div>
          <section className="graph" style={{padding: "40px"}}>
              <div className="card full-width">
                  <h4 style={{color:"#777"}}>Average Tokens Per Hour</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid vertical={false} strokeDasharray="5 5" />
                        <XAxis dataKey="hourBlockDisplay" />
                        <YAxis />
                        <Tooltip position={{ y: barGraphData.y - 40 }} cursor={{fill: '#0f0f0f'}} formatter={(value, name, props) => [value, name === 'tokensUsed' ? 'Tokens Processed' : name]} contentStyle={{ background: '#111', color: '#fff', border: 'none', borderRadius: '10px' }} />
                        <Bar dataKey="tokensUsed" name="Tokens Processed" fill="#8884d8" onMouseOver={(data) => {
          
          setBarGraphData(data)
        }}/>
                        {/* Optionally add another bar for avgTokensPerSec if desired */}
                    </BarChart>
                </ResponsiveContainer>
                        
              </div>
          </section>


          <section className="graph" style={{padding: "40px"}}>
              <div className="card full-width">
                  <h4 style={{color:"#777"}}>Average Tokens Per Second</h4>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid vertical={false} strokeDasharray="5 5" />
                        <XAxis dataKey="hourBlockDisplay" />
                        <YAxis />
                        <Tooltip position={{ y: barGraphData.y - 40 }} cursor={{fill: '#0f0f0f'}} formatter={(value, name, props) => [value, name === 'tokensPerSec' ? 'Tokens Per Sec' : name]} contentStyle={{ background: '#111', color: '#fff', border: 'none', borderRadius: '10px' }} />
                        <Bar dataKey="tokensPerSec" name="Tokens Per Sec" fill="#84bbd6" onMouseOver={(data) => {
        //   console.log('data', data)
          setBarGraphData(data)
        }}/>
                        {/* Optionally add another bar for avgTokensPerSec if desired */}
                    </BarChart>
                </ResponsiveContainer>
                        
              </div>
          </section>
      </main>
    </>
  );
};

export default Home;
