import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="text-light py-5" style={{backgroundColor: "#050505"}}>
      <div className="container">
        <div className="row">
          <div className="col-md-6"> 
          {/* <img src="/static/icon.png" alt="scri.ai" height="50px" /> */}
          
          <br />

            {/* <img src="/static/logo.png" alt="scri.ai" height="50px" /><br /> */}
           <br />
            <h5>scri.ai</h5>
            <p style={{textDecoration: "none", color: "#CCC"}}>Distributed GPU and compute for LLM AI inference.<br /> WIP - PRIVATE TEST ONLY</p>
          </div>
          <div className="col-md-3">
            <h5>Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/" style={{textDecoration: "none", color: "#999"}}>Home</Link>
              </li>
              <li>
                <Link to="/download" style={{textDecoration: "none", color: "#999"}}>Download</Link>
              </li>
              <li>
                <Link to="/leaderboard" style={{textDecoration: "none", color: "#999"}}>Leaderboards</Link>
              </li>
              <li>
                <Link to="#" style={{textDecoration: "none", color: "#999"}}>Documentation</Link>
              </li>
              <li>
                <Link to="/api" style={{textDecoration: "none", color: "#999"}}>API</Link>
              </li>
              {/* <li>
                <Link to="https://twitter.com/SCRI_AI" className="text-light"><i className="fa-brands fa-twitter" /> Twitter</Link>
              </li>
              <li>
                <Link to="https://discord.gg" className="text-light"><i className="fa-brands fa-discord" /> Discord</Link>
              </li> */}
              {/* <li>
                <Link to="/dashboard" className="text-light">Dashboard</Link>
              </li>
              <li>
                <Link to="/workers" className="text-light">Workers</Link>
              </li>
              <li>
                <Link to="/apidash" className="text-light">API Dashboard</Link>
              </li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Socials</h5>
            <ul className="list-unstyled">
            <li>
                <Link to="https://twitter.com/SCRI_AI" style={{textDecoration: "none", color: "#999"}}><i className="fa-brands fa-twitter" /> Twitter</Link>
              </li>
              <li>
                <Link to="https://discord.gg" style={{textDecoration: "none", color: "#999"}}><i className="fa-brands fa-discord" /> Discord</Link>
              </li>

            </ul>
          </div>
        </div>
        <hr />
        <div className="text-center">
          Copyright &copy; {new Date().getFullYear()} scri.ai. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;