import React, { useState, useEffect, useCallback } from 'react';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { RiseLoader } from 'react-spinners';
import toast, { Toaster } from 'react-hot-toast';

import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createBurnInstruction, createBurnCheckedInstruction } from '@solana/spl-token';

const Wallet = () => {
  const [points, setPoints] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [address, setAddress] = useState('');
  const [solanaAddress, setSolanaAddress] = useState('');
  const [tokenbal, setTokenBal] = useState(0);
  const [solbal, setSolBal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const [assAddy, setAssAddy] = useState('');

  const { publicKey, connected } = useWallet();
  const refreshToken = localStorage.getItem('refreshToken');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const navigate = useNavigate();

  const { connection } = useConnection();

  useEffect(() => {
    fetchPoints();
  }, []);

  const createAssociatedTokenAccount = async (connection, userWallet, tokenMintAddress) => {
    const tokenMint = new PublicKey(tokenMintAddress);
    const associatedTokenAddress = await getAssociatedTokenAddress(tokenMint, userWallet);

    const account = await connection.getAccountInfo(associatedTokenAddress);
    if (account === null) {
      const transaction = new Transaction().add(
        createAssociatedTokenAccountInstruction(
          userWallet,
          associatedTokenAddress,
          userWallet,
          tokenMint
        )
      );

      const { blockhash } = await connection.getRecentBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = userWallet;

      const signedTransaction = await window.solana.signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signedTransaction.serialize());
      await connection.confirmTransaction(signature);
    }

    return associatedTokenAddress;
  };

  const refreshAccessToken = async () => {
    try {
      const response = await fetch('https://server.scri.ai/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        setToken(data.accessToken);
        return true;
      } else {
        console.error('Failed to refresh access token');
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return false;
    }
  };

  useEffect(() => {
    if (connected && publicKey) {
      console.log('Connected wallet address:', publicKey.toString());
      setAddress(publicKey.toString());
      setSolanaAddress(publicKey.toString());
      handleUpdateAddress(publicKey.toString());
      
    //   fetchPoints();
    //   fetchBalances();
    }
  }, [publicKey, connected]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(success => {
        if (!success) {
          console.log('Token refresh failed. User needs to log in again.');
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchPoints = async () => {
    try {
      const response = await fetch('https://server.scri.ai/points', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setPoints(data.points);
      setAddress(data.address);
  
      setLoading(false);
  
    } catch (error) {
      console.error('Error fetching points:', error);
      toast.error('Failed to fetch points. Please try again later.', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  const fetchBalances = async () => {

    if (connected && publicKey) {
      const connection = new Connection('https://newest-frequent-breeze.solana-mainnet.quiknode.pro/45deaf24efb60c9c7a4421a340a8f484fb286e1b/');

      // Fetch SOL balance
      const solBalance = await connection.getBalance(publicKey);
      setSolBal(solBalance / 1e9);

      // Fetch token balance
      const tokenMintAddress = 'A4xF5KPso5wjmDPchAxEYqA3j9eVgfpMfSjBsgvKZ9zE';
      try {
        const associatedTokenAddress = await getAssociatedTokenAddress(new PublicKey(tokenMintAddress), publicKey);
        console.log('Associated token address:', associatedTokenAddress.toString());
        if (associatedTokenAddress !== "")
        {
          try {
            console.log('Associated token address:', associatedTokenAddress.toString());
            const tokenAccountInfo = await connection.getTokenAccountBalance(associatedTokenAddress);
            setTokenBal(tokenAccountInfo.value.uiAmount);
            setAssAddy(associatedTokenAddress.toString());
  
          } catch (error) {
            console.error('Error fetching token balance:', error);
            setTokenBal(0);
            try {
              const associatedTokenAddress = await createAssociatedTokenAccount(connection, publicKey, tokenMintAddress);
              console.log('Associated token address:', associatedTokenAddress.toString());
              if (associatedTokenAddress !== "")
              {
                setAssAddy(associatedTokenAddress.toString());
              }
          } catch (error) {
              console.error('Error creating associated token account:', error);
              toast.error('Failed to create associated token account. Please try again later.', {
              style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
              },
              });
          }

          }
        }

      } catch (error) {
          console.error('Error fetching associated token address:', error);
          try {
              const associatedTokenAddress = await createAssociatedTokenAccount(connection, publicKey, tokenMintAddress);
              console.log('Associated token address:', associatedTokenAddress.toString());
              if (associatedTokenAddress !== "")
              {
                setAssAddy(associatedTokenAddress.toString());
              }
          } catch (error) {
              console.error('Error creating associated token account:', error);
              toast.error('Failed to create associated token account. Please try again later.', {
              style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
              },
              });
          }
      }

    }
  };

  useEffect(() => {
    const fetchBalances = async () => {
      if (connected && publicKey) {
        const connection = new Connection('https://newest-frequent-breeze.solana-mainnet.quiknode.pro/45deaf24efb60c9c7a4421a340a8f484fb286e1b/');
        // maybe change RPC once on SSL https host
        // Fetch SOL balance
        const solBalance = await connection.getBalance(publicKey);
        setSolBal(solBalance / 1e9);
  
        // Fetch token balance
        const tokenMintAddress = 'A4xF5KPso5wjmDPchAxEYqA3j9eVgfpMfSjBsgvKZ9zE';
        try {
          const associatedTokenAddress = await getAssociatedTokenAddress(new PublicKey(tokenMintAddress), publicKey);
          console.log('Associated token address:', associatedTokenAddress.toString());
          if (associatedTokenAddress !== "") {
            try {
              console.log('Associated token address:', associatedTokenAddress.toString());
              const tokenAccountInfo = await connection.getTokenAccountBalance(associatedTokenAddress);
              setTokenBal(tokenAccountInfo.value.uiAmount);
              setAssAddy(associatedTokenAddress.toString());
            } catch (error) {
              console.error('Error fetching token balance:', error);
              setTokenBal(0);
              try {
                const associatedTokenAddress = await createAssociatedTokenAccount(connection, publicKey, tokenMintAddress);
                console.log('Associated token address:', associatedTokenAddress.toString());
                if (associatedTokenAddress !== "") {
                  setAssAddy(associatedTokenAddress.toString());
                }
              } catch (error) {
                console.error('Error creating associated token account:', error);
                toast.error('Failed to create associated token account. Please try again later.', {
                  style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                  },
                });
              }
            }
          }
        } catch (error) {
          console.error('Error fetching associated token address:', error);
          try {
            const associatedTokenAddress = await createAssociatedTokenAccount(connection, publicKey, tokenMintAddress);
            console.log('Associated token address:', associatedTokenAddress.toString());
            if (associatedTokenAddress !== "") {
              setAssAddy(associatedTokenAddress.toString());
            }
          } catch (error) {
            console.error('Error creating associated token account:', error);
            toast.error('Failed to create associated token account. Please try again later.', {
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            });
          }
        }
      }
    };
    fetchBalances();
  
    // const intervalId = setInterval(fetchBalances, 30000); // Call fetchBalances every 30 seconds
  
    // // Clean up the interval on component unmount
    // return () => {
    //   clearInterval(intervalId);
    // };

  }, [connected, publicKey]);

  const handleDeposit = async (e) => {
    e.preventDefault();
  
    if (depositAmount <= 0) {
      toast.error('Invalid deposit amount.');
      return;
    }
  
    if (depositAmount > tokenbal) {
      toast.error('Insufficient $SCRI balance.');
      return;
    }
  
    try {
      const connection = new Connection('https://newest-frequent-breeze.solana-mainnet.quiknode.pro/45deaf24efb60c9c7a4421a340a8f484fb286e1b/');
      const wallet = publicKey

      if (!connected) {
        toast.error('Wallet not connected.');
        setDisabled(false);

        return;
      }
  
      // Get the associated token account address
      const mintPublicKey = new PublicKey('A4xF5KPso5wjmDPchAxEYqA3j9eVgfpMfSjBsgvKZ9zE'); // Your token's mint address
      const ownerPublicKey = wallet; // User's wallet address
      const associatedTokenAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        ownerPublicKey
      );
  
      // Create the burn instruction
      const burnInstruction = createBurnCheckedInstruction(
        associatedTokenAddress, // Token account address
        mintPublicKey,          // Mint address
        ownerPublicKey,         // Owner of the token account
        depositAmount*1e9,    // Amount (in lamports, so multiplied by 1e9)
        9,                      // Decimals
        [],                     // Signers (none in this case, since the wallet will sign)
        TOKEN_PROGRAM_ID        // Token program ID
      );
  
      // Create a transaction and add the burn instruction
      const transaction = new Transaction().add(burnInstruction);
  
      // Sign the transaction with the user's wallet
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = ownerPublicKey;
      const signedTransaction = await window.solana.signTransaction(transaction);
  
      // Serialize the transaction and convert to base64 to send to the backend
      const serializedTransaction = signedTransaction.serialize();
      const base64 = serializedTransaction.toString('base64');

      setDisabled(true);

      toast.loading('Depositing tokens...', {
        id: 'deposit',
        style: {
            borderRadius: '10px',
            background: '#111',
            color: '#fff',
        },
        });
  
      // Send the serialized transaction to your backend
      const response = await fetch('https://server.scri.ai/deposit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ amount: parseInt(depositAmount), address: ownerPublicKey, transaction: base64 }),
      });
  
    const responseData = await response.json();

    // console.log(responseData)

      if (responseData.txhash) {
        const txhash = responseData.txhash;

        toast.success(`Deposit confirmed. Txhash: ${txhash.slice(0, 8)}...`, {
            id: 'deposit',
            style: {
              borderRadius: '10px',
              background: '#111',
              color: '#fff',
            },
          });
        setDisabled(false);
        navigate('/wallet');
        fetchPoints();
        fetchBalances();
        setDepositAmount(0);

        const timeoutId = setTimeout(() => {
            fetchBalances();
            }, 10000); // Wait for 10 seconds before fetching balances to give the blockchain time to update
        clearTimeout(timeoutId);
        // Update UI accordingly
      } else {
        toast.error('Deposit failed. Please try again.');
      }
    } catch (error) {
      console.error('Error depositing tokens:', error);
      toast.error('Failed to deposit tokens. Please try again.');
      setDisabled(false);
      fetchPoints();
        fetchBalances();
        setDepositAmount(0);
    }
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    var addy = address;

    if (withdrawAmount <= 0) {
      toast.error('Invalid withdrawal amount.', {
        style: {
          borderRadius: '10px',
          background: '#111',
          color: '#fff',
        },
      });
      return;
    }

    if (withdrawAmount > points) {
      toast.error('Insufficient points to withdraw.', {
        style: {
          borderRadius: '10px',
          background: '#111',
          color: '#fff',
        },
      });
      return;
    }



    try {
      const connection = new Connection('https://newest-frequent-breeze.solana-mainnet.quiknode.pro/45deaf24efb60c9c7a4421a340a8f484fb286e1b/');
      if (assAddy === '') {
          const tokenMintAddress = 'A4xF5KPso5wjmDPchAxEYqA3j9eVgfpMfSjBsgvKZ9zE';
          const associatedTokenAddress = await createAssociatedTokenAccount(connection, publicKey, tokenMintAddress);
          console.log('Associated token address:', associatedTokenAddress.toString());
          setAssAddy(associatedTokenAddress.toString());
      }

      setDisabled(true);

      toast.loading(`Withdraw pending...awaiting confirmation`, {
        id: 'withdraw',
        style: {
          borderRadius: '10px',
          background: '#111',
          color: '#fff',
        },
      });
  
      const response = await fetch('https://server.scri.ai/withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ amount: parseInt(withdrawAmount), address: assAddy }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      // disable withdraw button and set amount to 0
      setWithdrawAmount(0);
  
      if (data.txhash) {
        setTransactionStatus('pending');
        const txhash = data.txhash;
        toast.loading(`Withdraw transaction submitted. Txhash: ${txhash.slice(0, 8)}...`, {
          id: 'withdraw',
          style: {
            borderRadius: '10px',
            background: '#111',
            color: '#fff',
          },
        });
  
        const confirmTransaction = async () => {
          try {
            await connection.confirmTransaction(txhash);
            setTransactionStatus('confirmed');
            toast.dismiss('withdraw');
            toast.success(`Withdraw transaction confirmed. Txhash: ${txhash.slice(0, 8)}...`, {
              id: 'withdraw',
              style: {
                borderRadius: '10px',
                background: '#111',
                color: '#fff',
              },
            });
            fetchPoints();
            fetchBalances();
            setDisabled(false);
            const timeoutId = setTimeout(() => {
              fetchBalances();
            }, 3000);
            clearTimeout(timeoutId);
          } catch (error) {
            console.error('Error confirming transaction:', error);
            setTransactionStatus('failed');
            setDisabled(false);
            toast.dismiss('withdraw');
            toast.error(`Withdraw transaction failed. Please try again later.`, {
              id: 'withdraw',
              style: {
                borderRadius: '10px',
                background: '#111',
                color: '#fff',
              },
            });
          }
        };
  
        confirmTransaction();
      }
  
      fetchPoints();
      fetchBalances();
      setWithdrawAmount(0);
      setDisabled(false);
    } catch (error) {
      console.error('Error withdrawing points:', error);
      setDisabled(false);
      setWithdrawAmount(0);
      toast.dismiss('withdraw');
      toast.error(error.message, {
        style: {
          borderRadius: '10px',
          background: '#111',
          color: '#fff',
        },
      });
    }
  };

  const handleUpdateAddress = async (saddress) => {
    try {
      const response = await fetch('https://server.scri.ai/updateaddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ address: saddress }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      await response.json(); // If you need to use the response data, assign it to a variable
      fetchPoints();
      setSolanaAddress('');
    } catch (error) {
      console.error('Error updating Solana address:', error);
      toast.error('Failed to update Solana address. Please try again later.', {
        style: {
          borderRadius: '10px',
          background: '#111',
          color: '#fff',
        },
      });
    }
  };

  return (
    <>
    <div style={{ display: 'flex', height: '100%', direction: 'ltr'}}>
    <Toaster
    position="top-center"
    reverseOrder={false}
    toastOptions={{
      style: {
        border: '1px solid #009dff',
        padding: '16px',
        color: '#FFF',
        background: '#111',
      },
      iconTheme: {
        primary: '#713200',
        secondary: '#FFFAEE',
      },
    }}
  />
   <Sidebar collapsed={false} breakPoint="sm" className="sidebar" rootStyles={{
      container: "#0A0A0A",
      backgroundColor: '#0A0A0A',
      borderRight: '1px solid #222',
      minHeight: "100vh"
  }} backgroundColor='#0A0A0A'>
  <Menu rootStyles={{
      backgroundColor: '#0A0A0A',
      border: 'none'
  }}  menuItemStyles={{
    button: ({ level, active, disabled, hover }) => {
      // only apply styles on first level elements of the tree

        return {
          color: disabled ? '#eee' : '#fff',
          backgroundColor: active ? '#111' : '#0A0A0A',
          '&:hover': {
            backgroundColor: '#111',
          },
        };
    },
  }} className="menu">
    {/* <SubMenu label="Dashboard"> */}
    <MenuItem component={<Link to="/dashboard" />}><i className="fa-solid fa-gauge-high" style={{marginRight: "10px"}}></i> Dashboard </MenuItem>
      <MenuItem component={<Link to="/workers" />}><i className="fa-solid fa-computer" style={{marginRight: "10px"}}></i> Workers</MenuItem>
      <MenuItem disabled style={{color: "#888"}}><i className="fa-solid fa-wallet" style={{marginRight: "10px"}}></i> Wallet</MenuItem>
    {/* </SubMenu> */}
    <MenuItem component={<Link to="/apidash" />}><i className="fa-solid fa-key" style={{marginRight: "10px"}}></i> API Keys</MenuItem>
    <MenuItem><i className="fa-solid fa-file" style={{marginRight: "12px", marginLeft: "1px"}}></i> Documentation</MenuItem>
    <MenuItem><i className="fa-solid fa-gear" style={{marginRight: "10px"}}></i> Settings</MenuItem>
  </Menu>
</Sidebar>

{loading ? (
    <>
    <div style={{ margin: "150px 0 auto", textAlign: "center", width: "100%",  }}>
        <RiseLoader loading={loading} color="#fff" />
    </div>
    
    </>

) : (<>
<div style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
<div className="lg-container" style={{ marginTop: "20px", margin: "0 auto", textAlign: "center" }}>
    <br />
      <h1>Wallet</h1>
  <hr />
      {/* <p>Solana Connected Wallet: {address}</p> */}
      <WalletMultiButton />
      <hr />
      <div className="row">
        <div className="col"><br />
      <p><h2>{points}</h2>Points Balance</p></div>
      <div className="col"><br />
        <p><h2>{tokenbal}</h2> $SCRI Balance</p>
      </div>
      <div className="col"><br />
        <p><h2>{solbal}</h2> SOL Balance</p>
      </div>
      </div>
      <hr /><br />
      {assAddy && (
      <div className="row">
        <div className="col" style={{marginBottom: "50px"}}>
          <h4>Deposit $SCRI</h4>
          <p><em>Deposit $SCRI by burning and mint points</em></p>
          <form onSubmit={handleDeposit}>
            <input
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              placeholder="Amount"
              required
            /><br />
            <button type="submit" className="dl" disabled={disabled} style={{
                opacity: disabled ? 0.5 : 1,
            }}>Deposit</button>
          </form>
        </div>
        <div className="col">
          <h4>Withdraw $SCRI</h4>
          <p><em>Withdraw $SCRI by minting and burn points</em></p>
          <form onSubmit={handleWithdraw}>
            <input
              type="number"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              placeholder="Amount"
              required
            /><br />
            <button type="submit" className="dl" disabled={disabled} style={{
                opacity: disabled ? 0.5 : 1,
            }}>Withdraw</button>
          </form>
        </div>
      </div>
      )}

      {!assAddy && (
        <div>
          <p>Create an associated token account for $SCRI...</p>
        </div>
      )}

      </div>
    </div>
    </>)}
    </div>
    </>
  );
};

export default Wallet;