import React, { useState, useEffect } from 'react';
import useWebSocket from '../useWebSocket';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { RiseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import toast, { Toaster } from 'react-hot-toast';

const APIDash = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const refreshToken = localStorage.getItem('refreshToken');

  const [currentPage, setCurrentPage] = useState(0);
  const apiKeysPerPage = 5; // Number of API keys to display per page

  const [showModal, setShowModal] = useState(false);
  const [keyName, setKeyName] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedApiKey, setSelectedApiKey] = useState('');


  const navigate = useNavigate();

  const refreshAccessToken = async () => {
    try {
      const response = await fetch('https://server.scri.ai/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('token', data.accessToken);
        setToken(data.accessToken);
        return true;
      } else {
        console.error('Failed to refresh access token');
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return false;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(success => {
        if (!success) {
          console.log('Token refresh failed. User needs to log in again.');
          // Potentially redirect to login or perform another action
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
    }, 30000); // Refresh token every 1 minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleCreateApiKey = async () => {
    const token = localStorage.getItem('token'); // Ensure you're getting the token

    // key name sanitization
    if (!keyName) {
        toast.error('Key name is required',
        {
          style: {
            borderRadius: '10px',
            border: '1px solid #500e0e',
            background: '#280404',
            color: '#fff',
          },
        });
        return;
    }

    if (keyName.length < 3) {
        toast.error('Key name must be at least 3 characters',
        {
          style: {
            borderRadius: '10px',
            border: '1px solid #500e0e',
            background: '#280404',
            color: '#fff',
          },
        });
        return;
    }

    if (keyName.length > 20) {
        toast.error('Key name must not exceed 20 characters',
        {
          style: {
            borderRadius: '10px',
            border: '1px solid #500e0e',
            background: '#280404',
            color: '#fff',
          },
        });
        return;
    }
    
    try {
      const response = await fetch('https://server.scri.ai/api_keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ key_name: keyName }),
      });

      if (!response.ok) {
        throw new Error('Failed to create API key');
      }

      const data = await response.json();
      setApiKeys([...apiKeys, data]);
      setShowModal(false); // Close the modal after successful API key creation
      setKeyName(''); // Reset the key name field
      // refresh the page
      fetchAPIKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const handleDeleteApiKey = async (apiKey) => {

    const token = localStorage.getItem('token'); // Ensure you're getting the token

    try {
      const response = await fetch('https://server.scri.ai/delapi_key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ api_key: apiKey }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete API key');
      }

      const data = await response.json();

      console.log(data)

      toast.success('API key deleted successfully',
        {
            style: {
            borderRadius: '10px',
            border: '1px solid #0e500e',
            background: '#042804',
            color: '#fff',
            },
        });


    //   setApiKeys([...apiKeys, data]);
    //   setShowModal(false); // Close the modal after successful API key creation
    //   setKeyName(''); // Reset the key name field
      // refresh the page
      fetchAPIKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const fetchAPIKeys = async () => {
    setLoading(true);
  //   const token = localStorage.getItem('token'); // Adjust based on where you store the token
    try {
      const response = await fetch('https://server.scri.ai/user/api_keys', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // console.log(data)
      const sortedApiKeys = (data.apiKeys || []).sort((a, b) => b.createdAt - a.createdAt);
    
      setApiKeys(sortedApiKeys);
    } catch (error) {
      console.error('Error fetching API keys:', error);
      setError('Failed to fetch API keys. Please try again later.');
      localStorage.removeItem('token');

      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAPIKeys = async () => {
      setLoading(true);
    //   const token = localStorage.getItem('token'); // Adjust based on where you store the token
      try {
        const response = await fetch('https://server.scri.ai/user/api_keys', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // console.log(data)
        const sortedApiKeys = (data.apiKeys || []).sort((a, b) => b.createdAt - a.createdAt);
      
        setApiKeys(sortedApiKeys);
      } catch (error) {
        console.error('Error fetching API keys:', error);
        setError('Failed to fetch API keys. Please try again later.');
        localStorage.removeItem('token');

        navigate('/login');
      } finally {
        setLoading(false);
        
      }
    };

    fetchAPIKeys();
  }, []);

  // Function to handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Calculate the API keys to display on the current page
  const offset = currentPage * apiKeysPerPage;
  const currentPageApiKeys = apiKeys.slice(offset, offset + apiKeysPerPage);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

  return (
    <><Toaster />
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
    <Modal.Header>
        <Modal.Title>Delete API Key</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to delete this API key?</Modal.Body>
    <Modal.Footer>
        <button className="console" onClick={() => setShowDeleteModal(false)}>
        Cancel
        </button>
        <button className="keybtnsm" onClick={() => {
        handleDeleteApiKey(selectedApiKey);
        setShowDeleteModal(false); // Close the modal after deletion
        }}>
        Delete
        </button>
    </Modal.Footer>
    </Modal>

    <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Create New API Key</Modal.Title>
        </Modal.Header><br />
        <Modal.Body>
          <Form>

              <input
                type="text"
                value={keyName}
                onChange={(e) => setKeyName(e.target.value)}
                placeholder="Enter a name for your new API key"
                className="keyinput"
              />
  
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="keybtnsm" onClick={handleCreateApiKey}>
            Create Key
          </button>
          <button className="console" onClick={() => setShowModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    <div style={{ display: 'flex', height: '100%', direction: 'ltr'}}>
    <Sidebar collapsed={false} breakPoint="sm" className="sidebar" rootStyles={{
      container: "#0A0A0A",
      backgroundColor: '#0A0A0A',
      borderRight: '1px solid #222',
      minHeight: "100vh"
  }} backgroundColor='#0A0A0A'>
  <Menu rootStyles={{
      backgroundColor: '#0A0A0A',
      border: 'none'
  }}  menuItemStyles={{
    button: ({ level, active, disabled, hover }) => {
      // only apply styles on first level elements of the tree

        return {
          color: disabled ? '#eee' : '#fff',
          backgroundColor: active ? '#111' : '#0A0A0A',
          '&:hover': {
            backgroundColor: '#111',
          },
        };
    },
  }} className="menu">
    {/* <SubMenu label="Dashboard"> */}
    <MenuItem component={<Link to="/dashboard" />}><i className="fa-solid fa-gauge-high" style={{marginRight: "10px"}}></i> Dashboard </MenuItem>
      <MenuItem component={<Link to="/workers" />}><i className="fa-solid fa-computer" style={{marginRight: "10px"}}></i> Workers</MenuItem>
      <MenuItem component={<Link to="/wallet" />}><i className="fa-solid fa-wallet" style={{marginRight: "10px"}}></i> Wallet</MenuItem>
    {/* </SubMenu> */}
    <MenuItem disabled style={{color: "#888"}}><i className="fa-solid fa-key" style={{marginRight: "10px"}}></i> API Keys</MenuItem>
    <MenuItem><i className="fa-solid fa-file" style={{marginRight: "12px", marginLeft: "1px"}}></i> Documentation</MenuItem>
    <MenuItem><i className="fa-solid fa-gear" style={{marginRight: "10px"}}></i> Settings</MenuItem>
  </Menu>
</Sidebar>

{loading ? (
    <>
    <div style={{ margin: "150px 0 auto", textAlign: "center", width: "100%" }}>
        <RiseLoader loading={loading} color="#fff" />
    </div>
    
    </>

) : (<>
    <div style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
<br />
      <div className="lg-container" style={{ margin: "0 auto", textAlign: "center", width: "100%" }}>
        <br />
    <div>
      <h2>API Keys</h2>
      <p style={{color: "#999"}}>Create & manage API keys for your account.</p>
      <button className="keybtn" onClick={() => setShowModal(true)}>
        Create API Key
      </button><br />
      <br />
      {apiKeys && apiKeys.length > 0 ? (
        <>

             <div className="table-responsive">
             <table className="table tablea">
               <thead>
                 <tr>
                   <th>Key Name</th>
                   <th>API Key</th>
                   <th>Created On</th>
                   <th>Last Used</th>
                   <th>Inference Count</th>
                   <th>Manage</th>     
                 </tr>
               </thead>
               <tbody>
                 {currentPageApiKeys.map((keys, index) => (
                   <tr key={index}>
                     <td>{keys.keyName}</td>
                       <td>{keys.apiKey}</td>
                     <td>{new Date(keys.createdAt * 1000).toLocaleDateString()}</td>
                     <td>{keys.lastUpdated ? new Date(keys.lastUpdated * 1000).toLocaleDateString() : "Unused"}</td>
                    <td>{keys.inferenceCount}</td>
                     <td><i className="fa fa-trash" style={{cursor: "pointer"}} onClick={() => {
      setSelectedApiKey(keys.apiKey);
      setShowDeleteModal(true);
    }}/></td>
                   </tr>
                 ))}
               </tbody>
             </table>
           </div>
           {/* Generations display and pagination controls */}
         <div>{/* Display generations using displayedGenerations array */}</div>
         <ReactPaginate
             previousLabel={'← Previous'}
             nextLabel={'Next →'}
             pageCount={Math.ceil(apiKeys.length / apiKeysPerPage)}
             onPageChange={handlePageClick}
             containerClassName={'pagination'}
             buttonClassName={'pagi'}
             pageClassName={'pagi'}
             previousLinkClassName={'pagi'}
             nextLinkClassName={'pagi'}
             disabledClassName={'pagi--disabled'}
             activeClassName={'pagi--active'}
           />
        
        </>
      ) : (
        <p>You have no API keys.</p>
      )}
    </div>

    </div>
    </div>
    </>)}
</div>
</>
  );
};

export default APIDash;
